<template>
  <div>
    <div class="form-group">
      <label>Customer Name</label>
      <h4>{{ data.customer_name }}</h4>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label>Venue</label>
          <input
            type="text"
            class="form-control"
            v-model="data.venue_name"
            disabled
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Table Number</label>
          <input
            type="text"
            class="form-control"
            v-model="data.place_name"
            disabled
          />
        </div>
      </div>
    </div>
    <!-- <div class="text-right mb-4">
      <div class="btn btn-success"><i class="fas fa-plus"></i> Add Product</div>
    </div> -->
    <table class="table table-bordered border-dark">
      <thead>
        <tr>
          <th>Product</th>
          <th>Qty</th>
          <th>Status</th>
          <th>Price</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in data.order_detail" :key="order.id">
          <td
            v-if="order.order_additional.length != 0"
            data-toggle="modal"
            :data-target="`#exampleModal-` + order.id"
            style="color: #6777ef; cursor: pointer"
          >
            <void-paid :order="order"></void-paid>
          </td>
          <td
            v-else-if="order.ladies_category.length != 0"
            data-toggle="modal"
            :data-target="`#exampleModal1-` + order.id"
            style="color: #6777ef; cursor: pointer"
          >
            <void-paid :order="order"></void-paid>
          </td>
          <td v-else>
            <void-paid :order="order"></void-paid>
          </td>
          <td>{{ order.quantity }}</td>
          <td>{{ order.status }}</td>
          <td>Rp. {{ formatPrice(order.price) }}</td>
          <td>Rp. {{ formatPrice(order.total_price) }}</td>
          <the-modal
            :modal-id="order.id"
            :modal-title="order.name_product"
          ></the-modal>
          <modal-ladies
            :order-id="data.order_id"
            :ladies-list="data.ladies_list"
            :ladies-id="order.ladies_category[0]"
            :modal-id="order.id"
            :modal-title="order.name_product"
          ></modal-ladies>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ModalLadies from './ModalLadies.vue';
import TheModal from './Modal.vue';
import VoidPaid from './VoidPaidTable.vue';

export default {
  props: ['data'],
  components: { TheModal, ModalLadies, VoidPaid },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
};
</script>
