<template>
  <div
    class="modal fade"
    :id="`exampleModal-` + modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="false"
    v-if="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table mt-3">
            <thead>
              <th>Product Name</th>
              <th>Qty</th>
              <th>Total Price</th>
            </thead>
            <tbody>
              <tr v-for="d in data" :key="d.id">
                <td>{{ d.product_name }}</td>
                <td>{{ d.count }}</td>
                <td>Rp. {{ formatPrice(d.price) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet } from "../../../services/api";
export default {
  props: ["modalTitle", "modalId"],
  data() {
    return {
      id: this.modalId,
      data: [],
      dialog: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.modalId;
      apiGet("order_department/" + id)
        .then((result) => {
          this.data = result.data.data.order_additional;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
