<template>
  <div>
    <del v-if="order.status == 'VOID' || order.status == 'EXPIRED'">
      {{ order.name_product }}
    </del>
    <div v-else>
      {{ order.name_product }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["order"],
};
</script>
