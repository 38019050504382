<template>
  <div
    class="modal fade"
    :id="`exampleModal1-` + modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="false"
    v-if="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="ladiesInsert">
            <div class="form-group">
              <label>Ladies Name</label>

              <model-list-select
                v-for="(item, index) in ladiesId.qty"
                :key="index"
                :list="ladies"
                class="mt-3"
                v-model="form.ladies_id[index]"
                option-value="id"
                option-text="name"
                placeholder="Select Ladies"
              >
              </model-list-select>

              <div class="text-right">
                <button type="submit" class="btn btn-primary mt-3 ">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGet, apiPost } from "../../../services/api";
export default {
  props: ["modalTitle", "modalId", "orderId", "ladies-id", "ladieList"],
  data() {
    return {
      id: this.modalId,
      dialog: true,
      form: {
        order_id: this.orderId,
        ladies_id: [],
      },
      ladies: [],
    };
  },
  mounted() {
    apiGet("ladies/by-category/" + this.ladiesId.category_ladies_id).then(
      (res) => {
        this.ladies = res.data.data;
      }
    );
  },
  methods: {
    ladiesInsert() {
      let formData = new FormData();
      for (let index = 0; index < this.ladiesId.qty; index++) {
        formData.set("order_id", this.form.order_id);
        formData.set("ladies_id", this.form.ladies_id[index]);
        apiPost("ladies_order/store", formData)
          .then(() => {
            this.$noty.success("Your Ladies has been saved!");
            this.form.ladies_id[index] = "";
            this.$parent.$parent.getData();
          })
          .catch((res) => console.log(res));
      }
    },
  },
};
</script>
